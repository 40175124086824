export const CONTENT_TYPES = {
  EXTERNAL_LINK: 'EXTERNAL_LINK',
  HEADING: 'HEADING',
  HTML: 'HTML',
  GEO: 'GEO',
  GRID: 'GRID',
  INTERNAL_LINK: 'INTERNAL_LINK',
  IMAGE: 'IMAGE',
  GDRIVE_IMAGE: 'GDRIVE_IMAGE',
  VIDEO: 'VIDEO',
  TEXT: 'TEXT',
  LIST: 'LIST'
};

export const CATEGORIES = {
  NORTH_AMERICA: 'NORTH_AMERICA',
  GEAR: 'GEAR',
  CENTRAL_AMERICA: 'CENTRAL_AMERICA',
  SOUTH_AMERICA: 'SOUTH_AMERICA'
};
